import React, { Component } from 'react';
import '../carousel.css';
import d1 from '../assets/1.jpg';
import d2 from '../assets/2.jpg';
import d3 from '../assets/3.jpg';
import d4 from '../assets/4.jpg';
import d5 from '../assets/5.jpg';
import d6 from '../assets/6.jpg';
import d7 from '../assets/7.jpg';
import d8 from '../assets/8.jpg';
import d9 from '../assets/9.jpg';
import d10 from '../assets/10.jpg';
import d18 from '../assets/18.jpg';
import d19 from '../assets/19.jpg';
import d20 from '../assets/20.jpg';
import d21 from '../assets/21.jpg';
import d22 from '../assets/22.jpg';
import d23 from '../assets/23.jpg';
import d24 from '../assets/24.jpg';
import d25 from '../assets/25.jpg';
import d26 from '../assets/26.jpg';
import d27 from '../assets/27.jpg';
import d28 from '../assets/28.jpg';
import d30 from '../assets/30.jpg';

 
class Carousel extends Component {

    render() {
        return (

            <div class="boxWrapC">
                <div class="mintH">NFT GALLERY</div >

                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d10} alt="d5" />
                        </div>

                    </div>
                </div>

                <div class="slider2">
                    <div class="slide-track3">
                        <div class="slide">
                            <img class="carouselIMG2" src={d18} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d19} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d20} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d21} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d22} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d23} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d24} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d25} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d26} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG2" src={d27} alt="d5" />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Carousel;        