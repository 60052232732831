import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/Peter-Obi-Logo-150x150.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">
                    <div class="logoF">A NEW NIGERIA IS POSSIBLE WITH <br /> PETER OBI</div>

                </div>
                <div class="bottom">
                <div class="socialMain">
                        <div class="social">Website- <a href="https://obidientnftartinitiative.com" target="_blank">https://obidientnftartinitiative.com</a></div>
                        <div class="social">Youtube- <a href="https://www.youtube.com/@obidientnftartinitiative" target="_blank">https://www.youtube.com/@obidientnftartinitiative</a></div>
                        <div class="social">Instagram- <a href="https://www.instagram.com/obidientnftartinitiative" target="_blank">https://www.instagram.com/obidientnftartinitiative</a></div>
                        <div class="social">Twitter- <a href="https://twitter.com/obidientnftart" target="_blank">https://twitter.com/obidientnftart</a></div>
                        <div class="social">Pinterest- <a href="https://www.pinterest.com/obidientnftart" target="_blank">https://www.pinterest.com/obidientnftart</a></div>
                        <div class="social">Facebook- <a href="https://www.facebook.com/profile.php?id=100089766102740" target="_blank">https://www.facebook.com/profile.php?id=100089766102740</a></div>
                        <div class="social">Tiktok- <a href="https://www.tiktok.com/@obidientnftartinitiative" target="_blank">https://www.tiktok.com/@obidientnftartinitiative</a></div>
                        <div class="social">Discord- <a href="https://discord.gg/GhuhmJPa" target="_blank">https://discord.gg/GhuhmJPa</a></div>
                    </div>
                    <div class="socialMain2">
                        <img src={logo} />
                        <div class="copyright">Copyright © 2023 Obidient Art NFTs. All Rights Reserved</div>
                    </div>
                    
                </div>

                <div class="socialMainMob">
                        <div class="social">Website- <a href="https://obidientnftartinitiative.com" target="_blank">https://obidientnftartinitiative.com</a></div>
                        <div class="social">Youtube- <a href="https://www.youtube.com/@obidientnftartinitiative" target="_blank">https://www.youtube.com/@obidientnftartinitiative</a></div>
                        <div class="social">Instagram- <a href="https://www.instagram.com/obidientnftartinitiative" target="_blank">https://www.instagram.com/obidientnftartinitiative</a></div>
                        <div class="social">Twitter- <a href="https://twitter.com/obidientnftart" target="_blank">https://twitter.com/obidientnftart</a></div>
                        <div class="social">Pinterest- <a href="https://www.pinterest.com/obidientnftart" target="_blank">https://www.pinterest.com/obidientnftart</a></div>
                        <div class="social">Facebook- <a href="https://www.facebook.com/profile.php?id=100089766102740" target="_blank">https://www.facebook.com/profile.php?id=100089766102740</a></div>
                        <div class="social">Tiktok- <a href="https://www.tiktok.com/@obidientnftartinitiative" target="_blank">https://www.tiktok.com/@obidientnftartinitiative</a></div>
                        <div class="social">Discord- <a href="https://discord.gg/GhuhmJPa" target="_blank">https://discord.gg/GhuhmJPa</a></div>
                    </div>
            </div>
        )
    }
}

export default Footer;

