import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
              
                <div class="storyH">I'M NOT DESPERATE TO BE PRESIDENT, I'M DESPERATE TO SEE NIGERIA WORK </div>
                <div class="ob">- PETER OBI -</div>

                <div class="about">
                    <div class="storyCon">
                        <p class="storyCon2">“I will move Nigeria from a consuming nation to a producing nation. As a Nigerian, I want all parts of the country to be secured.”</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default Story;

