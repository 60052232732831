import './App.css';
import React, { Component } from 'react';
import Story from "./components/story";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import os from './assets/opensea.png';
import Carousel from './components/carousel';
import Footer from './components/footer';
import logo from './assets/logo.png';
import discord from './assets/discord.png';
import nft from './assets/PO.1.jpg';
import up from './assets/arrow-up.png';
import $ from "jquery";
import bg from './assets/flag.mp4';
import nft2 from './assets/Obi_Datti 61.jpg';
import video from './assets/video.mkv';
import twitter from './assets/twitter.png';
import facebook from './assets/facebook.png';
import tiktok from './assets/tiktok.png';
import instagram from './assets/instagram.png';
import pinterest from './assets/pinterest.png';
import hand from './assets/handshake.png';
import youtube from './assets/youtube.png';
import Welcome from './components/welcome';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

$(document).ready(function () {
	const stickyButton = $("#sticky-button");
	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 200) {
			stickyButton.css("visibility", "visible");
		} else {
			stickyButton.css("visibility", "hidden");
		}
	});
});

const opensea = () => {
	window.open("https://opensea.io/collection/obidient-art-nfts");
}

const opensea2 = () => {
	window.open("https://opensea.io/collection/obi-datti-nfts");
}

const tweet = () => {
	window.open("https://twitter.com/obidientnftart");
}

const dis = () => {
	window.open("https://discord.gg/aeUKw8HKxu");
}

const pinter = () => {
	window.open("https://www.pinterest.com/obidientnftart/");
}

const insta = () => {
	window.open("https://www.instagram.com/obidientnftartinitiative/");
}

const tktk = () => {
	window.open("https://www.tiktok.com/@obidientnftartinitiative");
}

const fb = () => {
	window.open("https://www.facebook.com/people/Obidient-Nft-Art/100089647216970");
}

const yt = () => {
	window.open("https://www.youtube.com/@obidientnftartinitiative");
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let onlyLeft;
let owner;
let publicSale;
let public_sale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;

let account2;
let mintAmount2 = 1;
let valueOfNFTs2 = 0;
let totalSupplyNFT2;
let onlyLeft2;
let owner2;
let publicSale2;
let public_sale_cost2;
let MAX_PER_WALLET2;
let public_mint_status2 = false;
let setEmail;
let email = [];
let emailAddress = [];

const ABIOBI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_email",
				"type": "bytes"
			}
		],
		"name": "setEmail",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "email",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const addressOBI = "0x189698046486aD75e28f3DEE4fC92B1fffF6fA5a";

const ABIDATTI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_email",
				"type": "bytes"
			}
		],
		"name": "setEmail",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "email",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const addressDATTI = "0xeA11d978CC6FD5603EdbC466b67fe70ED1021Ad2";

let contractOBI;
let contractDATTI;

let images = [
	"./assets/cover1.jpg",
	"./assets/cover2.jpg",
	"./assets/cover3.jpg",
	"./assets/cover4.jpg",
	"./assets/cover5.jpg"
];

let currentImageIndex = 0;

const contElement = $(".cont");


class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		nftMintingAmount: '1',
		totalValue: "8000000000000000",
		maxmint: '',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,

		walletAddress2: "",
		totalSupply2: "",
		nextPrice2: "",
		nextSessionAmount2: "",
		onlyLeftAmount2: "",
		nftMintingAmount2: '1',
		totalValue2: "8000000000000000",
		maxmint2: '',
		_public_sale_cost2: '',
		_presalesale_cost2: '',
		_MAX_PER_WALLET2: '',
		_public_mint_status2: '',
		_presale_status2: '',
		_cost2: '',
		_mintStatus2: false,
		setEmail: '',
		_email: '',
		_email2: []
	}

	async componentDidMount() {



	}

	onSubmitMinusOBI = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;



				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlusOBI = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitMinusDATTI = async event => {
		event.preventDefault();

		mintAmount2 = mintAmount2 - 1;

		if (mintAmount2 < 1) {
			mintAmount2 = 1
		}


		if (owner2 == account2) {
			console.log("owner2 : " + owner2)
			onlyLeft2 = 10000 - totalSupplyNFT2;

			if (mintAmount2 > onlyLeft2) {
				mintAmount2 = onlyLeft2;
			}

			valueOfNFTs2 = mintAmount2 * 0;


			this.setState({ nftMintingAmount2: mintAmount2 });

			this.setState({ totalValue2: valueOfNFTs2 });
		} else {


			if (totalSupplyNFT2 < 10000) {

				onlyLeft2 = MAX_PER_WALLET2 - publicSale2;

				if (mintAmount2 > onlyLeft2) {
					mintAmount2 = onlyLeft2;
				}
				valueOfNFTs2 = mintAmount2 * this.state._cost2;



				this.setState({ nftMintingAmount2: mintAmount2 });

				this.setState({ totalValue2: valueOfNFTs2 });

			}
		}
	}

	onSubmitPlusDATTI = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount2 = mintAmount2 + 1;

		if (owner2 == account2) {
			console.log("owner2 : " + owner2)
			onlyLeft2 = 10000 - totalSupplyNFT2;

			if (mintAmount2 > onlyLeft2) {
				mintAmount2 = onlyLeft2;
			}

			valueOfNFTs2 = mintAmount2 * 0;



			this.setState({ nftMintingAmount2: mintAmount2 });

			this.setState({ totalValue2: valueOfNFTs2 });
		} else {

			if (totalSupplyNFT2 < 10000) {

				onlyLeft2 = MAX_PER_WALLET2 - publicSale2;
				console.log(onlyLeft2);

				if (mintAmount2 > onlyLeft2) {
					mintAmount2 = onlyLeft2;
				}
				valueOfNFTs2 = mintAmount2 * this.state._cost2;

				this.setState({ nftMintingAmount2: mintAmount2 });

				this.setState({ totalValue2: valueOfNFTs2 });

			}
		}
	}

	onSubmit2OBI = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contractOBI.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contractOBI.methods.mint(mintAmount * 1).send({ gasLimit: 685000, from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contractOBI.methods.mint(mintAmount * 1).send({ gasLimit: 685000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	onSubmit2DATTI = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress2);

		try {
			let owner2 = await contractDATTI.methods.owner().call();


			if (account2 != owner2) {


				try {

					console.log("minAmount2:" + mintAmount2);
					console.log("valueOfNFTs2:" + valueOfNFTs2);

					this.setState({ statusError2: false, statusLoading2: true });
					await contractDATTI.methods.mint(mintAmount2 * 1).send({ gasLimit: 685000, from: account2, value: this.state.totalValue2 * 1 });
					this.setState({ statusLoading2: false, success2: true });
					console.log("Mint Amount2 :" + this.state.mintAmount2);

				} catch (err) {
					this.setState({ errorMassage2: "ERROR : " + err.message, statusLoading2: false, success2: false, statusError2: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount2:" + mintAmount2);
					console.log("valueOfNFTs2:" + valueOfNFTs2);


					this.setState({ statusError2: false, statusLoading2: true });
					await contractDATTI.methods.mint(mintAmount2 * 1).send({ gasLimit: 685000, from: account2, value: this.state.totalValue2 * 0 });
					this.setState({ statusLoading2: false, success2: true });
					console.log("Mint Amount2 :" + this.state.mintAmount2);

				} catch (err) {
					this.setState({ errorMassage2: "ERROR : " + err.message, statusLoading2: false, success2: false, statusError2: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnectOBI = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contractOBI = new web3.eth.Contract(ABIOBI, addressOBI);
			console.log("contractOBI :" + contractOBI);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFT = await contractOBI.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contractOBI.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contractOBI.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contractOBI.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contractOBI.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contractOBI.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}




						try {

							totalSupplyNFT = await contractOBI.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contractOBI.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contractOBI.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contractOBI.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contractOBI.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							owner = await contractOBI.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;




								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletConnectDATTI = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account2 = accounts[0];
			this.setState({ walletAddress2: account2 });

			contractDATTI = new web3.eth.Contract(ABIDATTI, addressDATTI);
			console.log("contractDATTI :" + contractDATTI);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}





						try {

							totalSupplyNFT2 = await contractDATTI.methods.totalSupply().call();
							this.setState({ totalSupply2: totalSupplyNFT2 });
							console.log("Total Supply2:" + totalSupplyNFT2);

							publicSale2 = await contractDATTI.methods.balanceOf(account).call();
							this.setState({ myNFTWallet2: publicSale2 });

							public_sale_cost2 = await contractDATTI.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost2: public_sale_cost2 });
							console.log("public_sale_cost2 :" + public_sale_cost2);
							this.setState({ _cost2: public_sale_cost2 });
							this.setState({ _mintStatus2: false });

							MAX_PER_WALLET2 = await contractDATTI.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET2: MAX_PER_WALLET2 });
							console.log("MAX_PER_WALLET2 :" + MAX_PER_WALLET2);

							public_mint_status2 = await contractDATTI.methods.public_mint_status().call();
							this.setState({ _public_mint_status2: public_mint_status2 });
							console.log("public_mint_status2 :" + public_mint_status2);

							owner2 = await contractDATTI.methods.owner().call();
							console.log("Owner2" + owner2);
							//..........................................................................//
							if (owner2 == account2) {
								console.log("owner2 : " + owner2)
								onlyLeft2 = 10000 - totalSupplyNFT2;

								if (mintAmount2 > onlyLeft2) {
									mintAmount2 = onlyLeft2;
								}

								valueOfNFTs2 = mintAmount2 * 0;



								this.setState({ nftMintingAmount2: mintAmount2 });

								this.setState({ totalValue2: valueOfNFTs2 });
							} else {
								mintAmount2 = 1;

								if (totalSupplyNFT2 == 10000) {

									onlyLeft2 = 10000 - totalSupplyNFT2;
									mintAmount2 = onlyLeft2;
									this.setState({ msg2: "SOLD OUT" });

								} else {
									mintAmount2 = 1;
									onlyLeft2 = MAX_PER_WALLET2 - publicSale2;

									if (mintAmount2 > onlyLeft2) {
										mintAmount2 = onlyLeft2;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs2 = mintAmount2 * this.state._cost2;



									this.setState({ nftMintingAmount2: mintAmount2 });

									this.setState({ totalValue2: valueOfNFTs2 });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}




						try {

							totalSupplyNFT2 = await contractDATTI.methods.totalSupply().call();
							this.setState({ totalSupply2: totalSupplyNFT2 });
							console.log("Total Supply2:" + totalSupplyNFT2);

							publicSale2 = await contractDATTI.methods.balanceOf(account).call();
							this.setState({ myNFTWallet2: publicSale2 });

							public_sale_cost2 = await contractDATTI.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost2: public_sale_cost2 });
							console.log("public_sale_cost2 :" + public_sale_cost2);
							this.setState({ _cost2: public_sale_cost2 });
							this.setState({ _mintStatus2: false });

							MAX_PER_WALLET2 = await contractDATTI.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET2: MAX_PER_WALLET2 });
							console.log("MAX_PER_WALLET2 :" + MAX_PER_WALLET2);

							public_mint_status2 = await contractDATTI.methods.public_mint_status().call();
							this.setState({ _public_mint_status2: public_mint_status2 });
							console.log("public_mint_status2 :" + public_mint_status2);



							owner2 = await contractDATTI.methods.owner().call();
							console.log("Owner2" + owner2);

							if (owner2 == account2) {
								console.log("owner2 : " + owner2)
								onlyLeft2 = 10000 - totalSupplyNFT2;

								if (mintAmount2 > onlyLeft2) {
									mintAmount2 = onlyLeft2;
								}

								valueOfNFTs2 = mintAmount2 * 0;




								this.setState({ nftMintingAmount2: mintAmount2 });

								this.setState({ totalValue2: valueOfNFTs2 });
							} else {
								mintAmount2 = 1;

								if (totalSupplyNFT2 == 10000) {

									onlyLeft2 = 0;

									if (mintAmount2 > onlyLeft2) {
										mintAmount2 = onlyLeft2;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount2 = 1;
									onlyLeft2 = MAX_PER_WALLET2 - publicSale2;

									if (mintAmount2 > onlyLeft2) {
										mintAmount2 = onlyLeft2;
									}

									valueOfNFTs2 = mintAmount2 * this.state._cost2;



									this.setState({ nftMintingAmount2: mintAmount2 });

									this.setState({ totalValue2: valueOfNFTs2 });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	email = async event => {
		event.preventDefault();

		try {

			const string = this.state._email;
			const bytes = Buffer.from(string, 'utf8');
			console.log("email : " + this.state._email);

			//await contractBidding.methods.setNickNameWithWallet(this.state._nickName).send({ gasLimit: 685000, from: account });
			this.setState({ statusError: false, statusLoadingEmail: true });
			await contractOBI.methods.setEmail(bytes).send({ gasLimit: 685000, from: account });
			this.setState({ _setEmail: setEmail });
			this.setState({ statusLoadingEmail: false, successEmail: true });

		} catch (err) {
			this.setState({ errorMassageEmail: "ERROR : " + err.message, statusLoadingEmail: false, successEmail: false, statusErrorEmail: true });
			console.log(err);


		}

		//window.location.reload();



	}

	getEmail = async event => {
		event.preventDefault();
		for (let x = 0; x < 20; x++) {
			email[x] = await contractOBI.methods.email(x).call();
			let hex_string = email[x].slice(2);
			emailAddress[x] = Buffer.from(hex_string, 'hex').toString('utf8');
			this.setState({ _email2: emailAddress });
		}
		console.log("emailAddress : " + emailAddress);

	}


	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="vidCont">
						<video class="videoBg2" src={video} autobuffer autoPlay loop muted playsInline></video></div>

					<div class="cont">

						<Element name="up">
							<div class="headers">

								<div class="h1">
									<div class="connect2Moile">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnectOBI}>
												<button class="button-87" >WALLET CONNECT</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-87" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>
									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="welcome" spy={true} smooth={true} duration={550}>WELOCOME</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="carousel" spy={true} smooth={true} duration={550}>GALLERY</Link></div>

									</div>

									<div class="right">

										<div class="icons">

											<div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>
											<div class="discord"><img class="osPic" onClick={dis} src={discord} /></div>
											<div class="discord"><img class="osPic" onClick={yt} src={youtube} /></div>
											<div class="discord"><img class="osPic" onClick={fb} src={facebook} /></div>
											<div class="discord"><img class="osPic" onClick={tktk} src={tiktok} /></div>
											<div class="discord"><img class="osPic" onClick={insta} src={instagram} /></div>
											<div class="discord"><img class="osPic" onClick={pinter} src={pinterest} /></div>

										</div>
										<div class="connect2">
											{this.state.walletAddress === '' ?
												(<form onSubmit={this.walletConnectOBI}>
													<button class="button-87" >WALLET CONNECT</button>
												</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-87" >
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

										</div>


									</div>

								</div>
								<div class="iconsMobile">

									<div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>
									<div class="discord"><img class="osPic" onClick={dis} src={discord} /></div>
									<div class="discord"><img class="osPic" onClick={yt} src={youtube} /></div>
									<div class="discord"><img class="osPic" onClick={fb} src={facebook} /></div>
									<div class="discord"><img class="osPic" onClick={tktk} src={tiktok} /></div>
									<div class="discord"><img class="osPic" onClick={insta} src={instagram} /></div>
									<div class="discord"><img class="osPic" onClick={pinter} src={pinterest} /></div>
								</div>
							</div>
						</Element>

						<div class="introduction">

							<div class="in2">
								<div class="intro">
									<img src={logo} />
								</div>
							</div>

						</div>

					</div>


					<Element name="about">
						<Story />
					</Element>

					<Element name="welcome">
					<Welcome />
					</Element>

					<Element name="mint">
						<video class="videoBg" src={bg} autobuffer autoPlay loop muted playsInline></video>

						<div class="mintMain">

							<div class="boxWrap2Mint">
								<div class="mintH">MINT</div >

								<div class="mintName">PETER OBI NFTs</div>
								<img class="osPic3" onClick={opensea} src={os} />
								<div class="mintDiv">
									<div class="pDiv"><img class="parrot" src={nft} /></div>
									<div class="mintCon">
										<div class="totalSupply">{this.state.totalSupply}/10000</div>
										<div class="price"><div>Mint Price 0 Ξ</div></div>

										<div class="minting_count_button">
											<div class="center">
												<form onSubmit={this.onSubmitMinusOBI}>
													<button class="btnfos-0-2" type="submit">-</button>
												</form>
											</div>

											<div>
												<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
											</div>

											<div class="center">
												<form onSubmit={this.onSubmitPlusOBI}>
													<button class="btnfos-0-2-2" type="submit">+</button>
												</form>
											</div>
										</div>

										{this.state.walletAddress === '' ?

											(<div class="mintbuttondiv">
												<form onSubmit={this.walletConnectOBI}>
													<button class="btnfos-0-3">Connect</button>
												</form></div>) :
											(<div class="mintbuttondiv">
												{this.state._mintStatus === true ? (
													<div>

														<form onSubmit={this.onSubmit2}>
															<button class="btnfos-0-3" type="submit">
																Mint Here</button>
														</form>

													</div>
												) : (<form onSubmit={this.onSubmit2OBI}>
													<button class="btnfos-0-3" type="submit">
														Mint Here</button>
												</form>)}
											</div>)}
										<div>

											{this.state.statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText">Minting your PETER OBI NFTs</div>
														<div class="loadTextSub">(this can take up to 30 seconds)</div>
													</div>
												</div>)
												: null}

											{this.state.success ? (

												<div><div class="successfully">MINTING SUCCESSFUL!</div>
													<div class="link"></div></div>)
												: null}

										</div>

									</div>
								</div>

							</div>

							<div class="boxWrap2Mint">
								<div class="mintH">MINT</div >

								<div class="mintName">OBI DATTI NFTs</div>
								<img class="osPic3" onClick={opensea2} src={os} />

								<div class="mintDiv">
									<div class="pDiv"><img class="parrot" src={nft2} /></div>
									<div class="mintCon">
										<div class="totalSupply">{this.state.totalSupply2}/10000</div>
										<div class="price"><div>Mint Price 0 Ξ</div></div>

										<div class="minting_count_button">
											<div class="center">
												<form onSubmit={this.onSubmitMinusDATTI}>
													<button class="btnfos-0-2" type="submit">-</button>
												</form>
											</div>

											<div>
												<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount2}</span></div>
											</div>

											<div class="center">
												<form onSubmit={this.onSubmitPlusDATTI}>
													<button class="btnfos-0-2-2" type="submit">+</button>
												</form>
											</div>
										</div>

										{this.state.walletAddress2 === '' ?

											(<div class="mintbuttondiv">
												<form onSubmit={this.walletConnectDATTI}>
													<button class="btnfos-0-3">Connect</button>
												</form></div>) :
											(<div class="mintbuttondiv">
												{this.state._mintStatus === true ? (
													<div>

														<form onSubmit={this.onSubmit2DATTI}>
															<button class="btnfos-0-3" type="submit">
																Mint Here</button>
														</form>

													</div>
												) : (<form onSubmit={this.onSubmit2DATTI}>
													<button class="btnfos-0-3" type="submit">
														Mint Here</button>
												</form>)}
											</div>)}
										<div>

											{this.state.statusError2 ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading2 ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText">Minting your OBI DATTI NFTs</div>
														<div class="loadTextSub">(this can take up to 30 seconds)</div>
													</div>
												</div>)
												: null}

											{this.state.success2 ? (

												<div><div class="successfully">MINTING SUCCESSFUL!</div>
													<div class="link"></div></div>)
												: null}

										</div>

									</div>
								</div>

							</div>
						</div>
					</Element>

					<Element name="carousel">
						<Carousel />
					</Element>
												
					<div class="emailMain">
					<div class="email">
						{this.state.walletAddress !== '' ?

							(<form onSubmit={this.email}>
								<div class="email2">
									<div class="emailCon">Join with us</div>
									<input
										type="email"
										pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
										placeholder='put your email address'
										value={this.state._email || ''}
										onChange={event => this.setState({ _email: event.target.value })}
										required
									></input>


									<button type='submit' class="submit">Submit</button>

									<div>

										{this.state.statusLoadingEmail ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Submitting</div>
												</div>
											</div>)
											: null}

										{this.state.successEmail ? (

											<div>
												<div>
													<div class="success2">Thank you for joining with us</div>
												</div>
											</div>)
											: null}

									</div>

								</div>
							</form>) :
							(<button class="submit" onClick={this.walletConnectOBI}><img class="handshake" src={hand} />Join with us </button>)}

						<div class="tokenIds" >
							{this.state._email2.map((element) => {

								console.log("ID :" + element);

								//var ID = element1[0] + 1;

								return (

									(<div class="imageSection">
										<div class="imageSectionSub">

											<div class="id">E_Address : {element}</div>
										</div>
									</div>));

							})}
						</div>
					</div>
					<Footer />

					</div>
				</div>

				<Link id="sticky-button" to="up" spy={true} smooth={true} duration={550}> <img class="stickyBtn2" src={up} /> </Link>

			</div >)
	}
}

export default App;
