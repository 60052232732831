import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Welcome extends Component {

    render() {
        return (

            <div class="boxWrap2Story2">

                <div class="storyH">Welcome to Obidient NFT Art Initiative</div>

                <div class="about">
                    <div class="storyCon2-2">
                        <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">We have created very beautiful NFTs of <span class="highLights">H.E. Peter Obi</span> & Senator <span class="highLights">Yusuf Baba Datti-Amed</span>,
                            the New Faces of our Vision of A New Nigeria, with H.E. Peter Obi as our President
                            and Senator Yusuf Baba Datti-Amed as our Vice-President.</p>
                        <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">We invite you to go straight to our NFT minting website page and <span class="highLights2">MINT FOR FREE</span> <span class="highLights">till 25th February 2023</span>. You <span class="highLights">do not need to pay</span> for "#Peter Obi NFTs" and "#Obi-Datti NFTs" except for transaction and Ethereum platform gas fees.</p>
                        <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">For those very new to Blockchain and Web3 Technology, click from our website to our Youtube page and watch step-by-step videos on how you can get started and mint "#Peter Obi NFTs" and "#Obi-Datti NFTs" and is hosted on OpenSea NFT platform, of which you may need to <span class="highLights">register with Opensea</span> to access your minted NFTs.</p>
                        <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">This Project is a voluntary independent project to promote H.E. Peter Obi; Baba Datti-Amen and the Vision for a New Nigeria. So please do mint the NFTs and spread the news about our principle and vision for A New Nigeria.
                            Also do connect to our social media pages - <span class="highLights">like us, share, re-tweet, re-post, and remember to subscribe to our email list </span>
                            from our website and social media sites for new updates, announcements, future events, and further web3 tech developments. </p>
                   
                        <p class="storyCon3" data-aos="fade-up" >Support this initiative with your donations @ ETH (Ethereum) Address: 0x969070022950a64DEaB9fac0442d10Bc5919581a</p>
                    </div>
                    
                </div>

            </div>
        )
    }
}

export default Welcome;